import React, { useState } from "react";
import { useSelector } from 'react-redux';
import SingleBrief from "./actions/SingleBrief";
import FirstSelection from "./actions/FirstSelection";

export default function DynamicBrief() {
    const isSubjectSelected = useSelector((state) => state.singleBrief.isSubjectSelected);

    return (
        <>
            {isSubjectSelected ?
                <SingleBrief />
                :
                <FirstSelection />
            }
        </>
    );
}

