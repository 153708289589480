import React from "react";
import "./Welcomepage.css";
import NavbarSaniba from "../../components/navigation/navbar/Navbar"
import WelcomeCards from "./WelcomeCards/WelcomeCards";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/logo/w-b-yellow-bg.svg"
import WelcomeProfiles from "./WelcomeProfiles/WelcomeProfiles";
import WelcomeWhatWeDo from "./WelcomeWhatWeDo/WelcomeWhatWeDo";
import WelcomeServices from "./WelcomeServices/WelcomeServices";
import WelcomeProducts from "./WelcomeProducts/WelcomeProducts";
import WelcomeFooter from "./WelcomeFooter/WelcomeFooter";
import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import WelcomeFeatures from "./WelcomeFeatures/WelcomeFeatures";
import WelcomeProfileBanner from "./WelcomeProfileBanner/WelcomeProfileBanner";

function Welcomepage() {
  return (
    <>
      <div className="WelcomePage">
        <NavbarSaniba style={{ position: "sticky" }} className="NavbarSaniba" />
        <Container>
          <Row>
            <Col sm={12}>
              <div className="brand-presentation">
                <Logo
                  className="brand-presentation-logo"
                  src={Logo}
                  alt="Saniba Logo" />
                <div className="brand-presentation-text"><h1><span className="brand-presentation-text-white">Worlds largest </span><span className="brand-presentation-text-green">digital <br /> product </span><span className="brand-presentation-text-white">platform.</span></h1></div>
              </div>
            </Col>
          </Row>
        </Container>
        <WelcomeCards />
      </div>
      <div className="WelcomeProfiles">
        <Container style={{ marginTop: "8rem" }}>
          <Row>
            <Col lg={12} sm={12}>
              <div className="brand-presentation">
                <div className="brand-presentation-text">
                  <h1 className="brand-presentation-text-white">Explore how everything done.</h1>
                  <h2 className="brand-presentation-text-white">See what your business can do with Saniba on its side and which methods we are using.</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <WelcomeProfiles />
        <WelcomeWhatWeDo />
        <WelcomeProfileBanner />
        <WelcomeBanner />
        <WelcomeFeatures />
        <WelcomeServices />
        <WelcomeProducts />
        <WelcomeFooter />
      </div>
    </>
  );
}

export default Welcomepage;
