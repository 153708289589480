import React, { useState, useEffect, useRef } from "react";
import './OzgurluguZorlaSocialView.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import InfiniteScroll from 'react-infinite-scroll-component';

function PostsStructure() {
    const [posts, setPosts] = useState([]);
    const [displayedPosts, setDisplayedPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        // Simulating fetching data from an API
        const fetchedPosts = [
            { image: 'https://picsum.photos/800', description: 'Description 1' },
            { image: 'https://picsum.photos/800', description: 'Description 2' },
            { image: 'https://picsum.photos/800', description: 'Description 3' },
            { image: 'https://picsum.photos/800', description: 'Description 4' },
            { image: 'https://picsum.photos/800', description: 'Description 5' },
            { image: 'https://picsum.photos/800', description: 'Description 6' },
            { image: 'https://picsum.photos/800', description: 'Description 7' },
            { image: 'https://picsum.photos/800', description: 'Description 8' },
            { image: 'https://picsum.photos/800', description: 'Description 9' },
            // Add more posts here
        ];
        setPosts(fetchedPosts);
        setDisplayedPosts(fetchedPosts.slice(0, 10)); // Display first 2 posts initially
    }, []);

    const fetchMorePosts = () => {
        if (displayedPosts.length >= posts.length) {
            setHasMore(false);
            return;
        }

        const newPosts = posts.slice(displayedPosts.length, displayedPosts.length + 2);
        setDisplayedPosts([...displayedPosts, ...newPosts]);
    };

    const scrollToNextPost = (index) => {
        const container = scrollContainerRef.current;
        const postElements = container.querySelectorAll('.post');
        if (postElements[index + 1]) {
            postElements[index + 1].scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="scrollable-container" ref={scrollContainerRef} id="scrollable-container">
            <InfiniteScroll
                dataLength={displayedPosts.length}
                next={fetchMorePosts}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollable-container"
            >
                {displayedPosts.map((post, index) => (
                    <InstagramPosts
                        key={index}
                        image={post.image}
                        description={post.description}
                        scrollToNext={() => scrollToNextPost(index)}
                    />
                ))}
            </InfiniteScroll>
        </div>

    );
}

const InstagramPosts = ({ image, description, scrollToNext }) => {

    return (
        <div className="post">
            <div className="image-container">
                <LazyLoadImage
                    src={image}
                    alt="Post"
                    className="post-image"
                    effect="blur"
                />
            </div>
            <div className="description-container">
                <p>{description}</p>
            </div>
            <button className="scroll-down-button" onClick={scrollToNext}>↓</button>
        </div>
    );
};

export default PostsStructure;
