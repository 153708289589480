import React, { useState } from "react";
import "./Authenticate.css";

import { auth, signInWithGooglePopup } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, Toaster } from "react-hot-toast";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import google from '../../assets/images/Google_Icons-09-512.webp'; // Tell webpack this JS file uses this image
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

function Authenticate() {

  //PHONE AUTH
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  

  //GOOGLE POPUP AUTH
  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    console.log(response);
  }
  const [active, setActive] = useState("login");

  const handleChange = () => {
    setActive(active === "login" ? "signup" : "login");
  };

  return (
    <div className="authenticate">
      <div className="auth__right">
        <div className="auth__more">
          <span>
            {active === "login" ? (
              <>
                <section className="bg-emerald-500 flex items-center justify-center h-screen">
                  <div>
                  <Toaster toastOptions={{ duration: 4000 }} />
                    <div id="recaptcha-container"></div>
                    {user ? (
                      <h2 className="text-center   font-medium text-2xl">
                        👍Login Success
                      </h2>
                    ) : (
                    <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
                      <h1 className="text-center leading-normal login-modal-title font-medium text-3xl mb-6">
                        Join <br /> Saniba
                      </h1>
                      {showOTP ? (
                        <>
                          <div className="text-emerald-500 w-fit mx-auto p-4 rounded-full">
                            <BsFillShieldLockFill size={30} />
                          </div>
                          <label
                            htmlFor="otp"
                            className="font-bold text-xl   text-center"
                          >
                          Enter your OTP
                          </label>
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            autoFocus
                            className="opt-container "
                          ></OtpInput>
                          <button
                            onClick={onOTPVerify}
                            className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5   rounded"
                          >
                          {loading && (
                            <CgSpinner size={20} className="mt-1 animate-spin" />
                          )}
                          <span>Verify OTP</span>
                          </button>
                        </>
                      ) : (
                      <>
                        <label
                            htmlFor=""
                            className="font-bold text-xl   text-center"
                          >
                            Verify your phone number
                        </label>
                        <PhoneInput className="authInput" country={"us"} value={ph} onChange={setPh} />
                        <button
                          onClick={onSignup}
                          className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5   rounded"
                        >
                        <span className="SignInSMSTxtBtn">Send code via SMS</span>
                        </button>
                        {/*<div className="SignInGoogleAlignment"
                          onClick={onSignup}
                          >
                          <EmailRoundedIcon sx={{ fontSize: 50 }} className="SignInSMSIcon"/>
                          {loading && (
                            <CgSpinner size={20} className="mt-1 animate-spin" />
                          )}
                          <button className="SignInSMSBtn authButton">Send code via SMS</button>
                          </div>*/}
                      </>
                    )}
                  </div>
                      )}
                    </div>
                  </section>
                  <div className="SignInGoogleAlignment">
                    <img onClick={logGoogleUser} className="SignInGoogleLogo" src={google} alt="Google Logo" />
                    <button className="authButton" onClick={logGoogleUser}>Sign In With Google</button>
                  </div>
                            </>
                          ) : (
                            <>
                              Have an account? <button onClick={handleChange}>Log in</button>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
  );
}

export default Authenticate;