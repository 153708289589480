import { combineReducers } from "redux";
const initialState = {
  briefId: null,
};

const rootReducer2 = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BRIEF_ID':
      return {
        ...state,
        briefId: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer2;
