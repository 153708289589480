import "./App.css";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { auth } from "./firebase";
import { loginUser, setLoading } from "./authenticate/features/userSlice";
import Welcomepage from "./userpages/welcome/Welcomepage";
import WelcomeOzgurluguZorla from "./ozgurluguzorla/WelcomeOzgurluguZorla";
import 'bootstrap/dist/css/bootstrap.min.css';
import './font.css'
import './styles/components.css'

import { Routes, Route, useLocation } from 'react-router-dom';

import Dashboard from "./userpages/projects/dashboard/Dashboard";
import Profile from "./userpages/profile/Profile";
import Offers from "./userpages/projects/offers/Offers";
import Updates from "./userpages/projects/updates/Updates";
import Documents from "./userpages/projects/documents/Documents";
import NotFound from "./userpages/NotFound/NotFound";
import Dynamicbrief from "./userpages/projects/ProjectorRouter";
import BeDealer from "./userpages/bedealer/BeDealer";
import UyapBotForm from "./uyap/uyapbot";
import UyapResponses from "./uyap/uyapresponse";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          loginUser({
            uid: authUser.uid,
            username: authUser.displayName,
            email: authUser.email,
          })
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        console.log("User is not logged in.");
      }
    });
  }, []);

  const user = useSelector((state) => state.data.user.user);
  const isLoading = useSelector((state) => state.data.user.isLoading);

  // Determine if we should use day or night mode based on the current route
  const isDayMode = location.pathname === "/ozgurlugu-zorla";

  return (
    <div className={`app ${isDayMode ? 'day-mode' : 'night-mode'}`}>
      {isLoading ? (
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      ) : (
        <>
          {user ? (
            <Routes>
              <Route path="/projects/dashboard/:projectType/:projectId/dashboard" element={<Dashboard />} />
              <Route path="/projects/dashboard/:projectType/:projectId" element={<Dashboard />} />
              <Route path="/projects/dashboard/:projectType/:projectId/offers" element={<Offers />} />
              <Route path="/projects/dashboard/:projectType/:projectId/updates" element={<Updates />} />
              <Route path="/projects/dashboard/:projectType/:projectId/documents" element={<Documents />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/billing" element={<Profile />} />
              <Route path="/profile/additional" element={<Profile />} />
              <Route path="/profile/logs" element={<Profile />} />
              <Route path="/projects" element={<Dynamicbrief />} />
              <Route path="/projects/brief/create/*" element={<Dynamicbrief />} />
              <Route path="/projects/brief/createai/*" element={<Dynamicbrief />} />
              <Route path="/apply" element={<BeDealer />} />
              <Route path="/uyapbot" element={<UyapBotForm />} />
              <Route path="/uyapresponses" element={<UyapResponses />} />

              {/* Geleceği Zorla */}
              <Route path="/ozgurlugu-zorla" element={<WelcomeOzgurluguZorla />} />

              <Route path="/" element={<Welcomepage />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Welcomepage />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/apply" element={<BeDealer />} />
              <Route path="/uyapbot" element={<UyapBotForm />} />
              <Route path="/uyapresponses" element={<UyapResponses />} />
            </Routes>
          )}
        </>
      )}
    </div>

  );
}

export default App;
