// src/singleBriefSlice.js
import { createSlice } from '@reduxjs/toolkit';

const singleBriefSlice = createSlice({
  name: 'singleBrief',
  initialState: {
    data: '',
    isSubjectSelected: false,
  },
  reducers: {
    updateSingleBrief(state, action) {
      state.data = action.payload;
    },
    toggleSubjectSelected(state) {
      state.isSubjectSelected = !state.isSubjectSelected;
    },
  },
});

export const { updateSingleBrief, toggleSubjectSelected } = singleBriefSlice.actions;
export default singleBriefSlice.reducer;
