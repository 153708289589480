import React, { useState, useRef, useEffect } from "react";
import "./WelcomeProfileBanner.css";
import SanibaBookImage from "../../../assets/images/bannerimages/SanibaBook.png";
import profileImage1 from "../../../assets/images/bannerimages/profileimages+1.png";
import profileImage2 from "../../../assets/images/bannerimages/profileimages+2.png";
import profileImage3 from "../../../assets/images/bannerimages/profileimages+3.png";
import profileImage4 from "../../../assets/images/bannerimages/profileimages+4.png";
import profileImage5 from "../../../assets/images/bannerimages/profileimages+5.png";
import profileImage6 from "../../../assets/images/bannerimages/profileimages+6.png";
import profileImage7 from "../../../assets/images/bannerimages/profileimages+7.png";
import profileImage8 from "../../../assets/images/bannerimages/profileimages+8.png";
import profileImage9 from "../../../assets/images/bannerimages/profileimages+9.png";

const WelcomeProfileBanner = () => {
    const sectionRef = useRef(null);
    const images = [profileImage1, profileImage2, profileImage3, profileImage4, profileImage5, profileImage6, profileImage7, profileImage8, profileImage9];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const images = document.querySelectorAll(".small-image");

                    if (entry.isIntersecting) {
                        images.forEach((img) => {
                            img.classList.remove("visible");
                        });

                        setTimeout(() => {
                            images.forEach((img, index) => {
                                setTimeout(() => {
                                    img.classList.add("visible");
                                }, index * 300); // Delay each image's appearance
                            });
                        }, 100); // Delay before re-adding the visible class
                    }
                });
            },
            { threshold: 0.5 } // Trigger when 50% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div ref={sectionRef} className="random-image-section">
            <div className="main-image-container">
                <img
                    src={SanibaBookImage}
                    alt="Saniba Book"
                    className="main-image"
                />
                {images.map((image, i) => (
                    <img
                        key={i}
                        src={image}
                        alt={`Small ${i + 1}`}
                        className="small-image"
                    />
                ))}

            </div>
        </div>
    );
};

export default WelcomeProfileBanner;