import React from "react";
import "./SidenavProfile.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../authenticate/features/userSlice";
import { auth } from "../../../firebase";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';

function SidenavProfile(props) {

  //IS USER LOGGED IN
  const user = useSelector((state) => state.data.user.user);

  //DIRECTING
  const navigate = useNavigate();

  const navigateDashboard = () => {
    // 👇️ navigate to /
    navigate('/profile');
  };

  const navigateOffers = () => {
    // 👇️ navigate to /
    navigate('/profile/billing');
  };

  const navigateUpdates = () => {
    // 👇️ navigate to /
    navigate('/profile/additional');
  };

  const navigateDocuments = () => {
    // 👇️ navigate to /
    navigate('/profile/logs');
  };


  let pathN = window.location.pathname;
  pathN = pathN.split('/').pop();
  if (!(pathN === "billing" || pathN === "additional" || pathN === "logs")) {
    pathN = "profile"
  }

  return (
    <div className="sidenav">
      <div className="sidenav__buttons">
        <button onClick={navigateDashboard} className={pathN === "profile" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>PROFILE</span>
        </button>
        <button onClick={navigateOffers} className={pathN === "billing" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>BILLING & PAYMENTS</span>
        </button>
        <button onClick={navigateUpdates} className={pathN === "additional" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>ADDITIONAL INFORMATIONS</span>
        </button>
        <button onClick={navigateDocuments} className={pathN === "logs" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>PREVIOS WORKS</span>
        </button>
      </div>
    </div>

  );
}
export default SidenavProfile;
