import React from "react";
import "./Sidenav.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../authenticate/features/userSlice";
import { auth } from "../../../firebase";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';

function Sidenav(props) {

  //IS USER LOGGED IN
  const user = useSelector((state) => state.data.user.user);

  //DIRECTING
  const navigate = useNavigate();

  const navigateDashboard = () => {
    // 👇️ navigate to /
    navigate(`/projects/dashboard/${props.projectType}/${props.projectId}`);
  };

  const navigateOffers = () => {
    // 👇️ navigate to /
    navigate(`/projects/dashboard/${props.projectType}/${props.projectId}/offers`);
  };

  const navigateUpdates = () => {
    // 👇️ navigate to /
    navigate(`/projects/dashboard/${props.projectType}/${props.projectId}/updates`);
  };

  const navigateDocuments = () => {
    // 👇️ navigate to /
    navigate(`/projects/dashboard/${props.projectType}/${props.projectId}/documents`);
  };

  const navigateProfile = () => {
    // 👇️ navigate to /
    navigate('/profile');
  };

  const returnProjects = async () => {
    navigate('/projects/');
  }

  let pathN = window.location.pathname;
  pathN = pathN.split('/').pop();
  if (!(pathN === "offers" || pathN === "updates" || pathN === "documents" || pathN === "profile")) {
    pathN = "dashboard"
  }

  return (
    <div className="sidenav">
      <div className="sidenav__buttons">
        <button onClick={navigateDashboard} className={pathN === "dashboard" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>DASHBOARD</span>
        </button>
        <button onClick={navigateOffers} className={pathN === "offers" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>OFFERS</span>
        </button>
        <button onClick={navigateUpdates} className={pathN === "updates" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>ALL UPDATES</span>
        </button>
        <button onClick={navigateDocuments} className={pathN === "documents" ? "sidenav__button__selected" : "sidenav__button sidenav__button__margin"}>
          <span>DOCUMENTS</span>
        </button>
        <div className="sidenav__minibuttons">
          <ArrowBackIcon onClick={() => returnProjects()} sx={{ fontSize: 52 }} className="returnMainPage__ArrowBack" />
          <PersonIcon onClick={navigateProfile} sx={{ fontSize: 52 }} className="returnMainPage__ArrowBack" />
          <ContrastRoundedIcon sx={{ fontSize: 52 }} className="returnMainPage__ArrowBack" />
        </div>
      </div>
    </div>

  );
}
export default Sidenav;
