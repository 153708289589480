import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import "./Briefbar.css"
import {ReactComponent as Logo} from "../../../assets/logo/y-w-navbar.svg"
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

function Briefbar(props) {
  let pathProject = true;
  if (window.location.pathname == '/projects' || window.location.pathname == '/projects/'){
    pathProject = false;
  } else {pathProject = true}

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    // 👇️ navigate to /contacts
    navigate(window.location.pathname);
  };

  return (
    <> 
      {
        pathProject ? 
          <>
            <Navbar onClick={navigateToDashboard} collapseOnSelect expand="lg" className="navbar">
              <Container>
                <Logo
                height="50"
                className='logo'
                />
                <Navbar.Brand className='BriefNavbar'>Brief Form</Navbar.Brand>
              </Container>
            </Navbar>
          </> : 
          
          <Navbar collapseOnSelect expand="lg" className="navbar">
          <Container>
            <Logo
              height="50"
              className='logo'
            />
            <Navbar.Brand href="">Saniba</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav>
                <Nav.Link className='MainItem' href="/projects">Client
                </Nav.Link>
                <Nav.Link className='MainItem' eventKey={2} href="/apply">
                  Dealer
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      }
    </>
  );
}

export default Briefbar;