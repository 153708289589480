import React, { useState, useEffect, useRef } from "react";
import "./WelcomeOzgurluguZorla.css";
import NavbarOzgurluguZorla from "../components/navigation/navbar/ozgurlugu-zorla/NavbarOzgurluguZorla"
import { ReactComponent as Logo } from "../assets/logo/ozgurlugu-zorla/ozgurlugu-zorla.svg"
import OzgurluguZorlaForm from "./Form/OzgurluguZorlaForm"
import OzgurluguZorlaSocialView from "./SocialView/OzgurluguZorlaSocialView";
import InfiniteScroll from 'react-infinite-scroll-component';

function WelcomeOzgurluguZorla() {


  return (
    <>
      <div className="WelcomeOzgurluguZorla">
        <NavbarOzgurluguZorla className="NavbarSaniba" />
        <section className="hero">
          <img src="/logo.png" alt="Logo" className="logo" />
          <h1 className="title">Özgürlüğe ilk adımınızı atın</h1>
        </section>
        <section className="content">
          <OzgurluguZorlaSocialView />
          <OzgurluguZorlaForm />
        </section>
      </div>
    </>
  );
}

export default WelcomeOzgurluguZorla;
