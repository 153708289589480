import React, { useState } from "react";
import "./WelcomeFooter.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function WelcomeFooter() {
  const [focusedInput, setFocusedInput] = useState(null);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  return (
    <footer className="container-fluid d-flex flex-column flex-md-row align-items-stretch footer-section">
      <div className="col-md-6 form-section d-flex flex-column justify-content-center p-5">
        <h1 className="form-title mb-4">Let's Talk</h1>
        <form className="w-100">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="yourName"
                  placeholder="Your Name"
                  onFocus={() => handleFocus('yourName')}
                  onBlur={handleBlur}
                />
                <label
                  htmlFor="yourName"
                  className={focusedInput === 'yourName' ? 'focused' : ''}
                >
                  Your Name
                </label>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="yourEmail"
                  placeholder="Your Email"
                  onFocus={() => handleFocus('yourEmail')}
                  onBlur={handleBlur}
                />
                <label
                  htmlFor="yourEmail"
                  className={focusedInput === 'yourEmail' ? 'focused' : ''}
                >
                  Your Email
                </label>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-floating">
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onFocus={() => handleFocus('phoneNumber')}
                  onBlur={handleBlur}
                />
                <label
                  htmlFor="phoneNumber"
                  className={focusedInput === 'phoneNumber' ? 'focused' : ''}
                >
                  Phone Number
                </label>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="yourCompany"
                  placeholder="Your Company"
                  onFocus={() => handleFocus('yourCompany')}
                  onBlur={handleBlur}
                />
                <label
                  htmlFor="yourCompany"
                  className={focusedInput === 'yourCompany' ? 'focused' : ''}
                >
                  Your Company
                </label>
              </div>
            </div>
          </div>
          <div className="form-floating mb-4">
            <textarea
              className="form-control"
              id="projectDescription"
              placeholder="Project Description"
              style={{ height: '100px' }}
              onFocus={() => handleFocus('projectDescription')}
              onBlur={handleBlur}
            ></textarea>
            <label
              htmlFor="projectDescription"
              className={focusedInput === 'projectDescription' ? 'focused' : ''}
            >
              Project Description
            </label>
          </div>
          <button type="submit" className="beDealerButton">
            Submit
          </button>
        </form>
      </div>
      <div className="col-md-6 contact-info-section d-flex flex-column p-5">
        <div>
          <h1 className="contact-title">Contact</h1>
          <h2 className="contact-subtitle">Phone</h2>
          <p>+1 xxx xxx xxxx</p>
          <h2 className="contact-subtitle">Email</h2>
          <p>contact@x.com</p>
        </div>
        <div>
          <h1 className="info-title">Information</h1>
          <h2 className="info-subtitle">Privacy Policy</h2>
        </div>
        <div className="contact-image">
          <img src="https://www.rebelcreative.art/backgrounds/bg-contact-us.png" width="66%" alt="Contact" className="img-fluid" />
        </div>
      </div>
    </footer>

  );
}

export default WelcomeFooter;
