import React, { useState, useRef, useEffect } from "react";
import "./WelcomeWhatWeDo.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import MarkChatUnreadRoundedIcon from '@mui/icons-material/MarkChatUnreadRounded';
import Background from "../../../assets/images/whatwedo.png"

function WelcomeWhatWeDo() {

  const navCardsRef = useRef(null);
  const contentRef = useRef(null);
  const [navCardsHeight, setNavCardsHeight] = useState(0);
  const [selectedCard, setSelectedCard] = useState(1);

  useEffect(() => {
    if (navCardsRef.current) {
      setNavCardsHeight(navCardsRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = `${navCardsHeight}px`;
    }
  }, [navCardsHeight]);

  return (
    <Container className="CardDisplayProfilesContainer">
      <img
        src={Background}
        style={{ width: '100%' }}
        alt="Saniba Logo" />
      <Row style={{ marginTop: "8rem" }}>
        <Col lg={12}>
          <div className="brand-presentation">
            <div className="brand-presentation-text">
              <h1 className="brand-presentation-text-white">What exactly different we do?</h1>
              <h2 className="brand-presentation-text-white">See what your business can do with Saniba on its side and which methods we are using.</h2>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

  );
}

export default WelcomeWhatWeDo;
