import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./Navbar.css"
import { auth } from "../../../firebase";
import { ReactComponent as Logo } from "../../../assets/logo/y-w-navbar.svg"
import PersonIcon from '@mui/icons-material/Person';

import Authenticate from '../../../authenticate/auth/Authenticate';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { loginUser, logoutUser, setLoading } from '../../../authenticate/features/userSlice';
import BriefModal from '../../modal/BriefModal/BriefModal';
import AreYouLeaving from '../../modal/AreYouLeaving/AreYouLeaving';

function NavbarSaniba() {
  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          loginUser({
            uid: authUser.uid,
            username: authUser.displayName,
            email: authUser.email,
          })
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        console.log("User is not logged in.");
      }
    });
  }, []);

  const user = useSelector((state) => state.data.user.user);
  const handleCopyUserId = () => {
    const userIdToCopy = user.uid; // Assuming userId is a string
    navigator.clipboard.writeText(userIdToCopy)
      .then(() => {
        console.log('User ID copied to clipboard:', userIdToCopy);
        alert('User ID copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy user ID to clipboard:', error);
        alert('Failed to copy User ID to clipboard. Please try again.');
      });

  };

  const handelLogout = () => {
    //dispatch(logoutUser());
    //signOut(auth);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar">
      <Container>
        <Logo
          height="50"
          className='logo'
        />
        <Navbar.Brand href="">Saniba</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link className='MainItem' href="">
              {user ?
                <BriefModal
                  title="Give a Brief?"
                  style={{ color: "#fff !important" }}
                  link={<Authenticate />}
                  content={<div>Hakkımızda</div>}
                />
                :
                <BriefModal
                  title="Give a Brief?"
                  style={{ color: "#fff !important" }}
                  link={<Authenticate />}
                  content={<div>Hakkımızda</div>}
                />
              }

            </Nav.Link>
            <Nav.Link className='MainItem' eventKey={2} href="/apply">
              Sipariş Ver
            </Nav.Link>
          </Nav>
          {/** user ? <>
            <NavDropdown title={ <PersonIcon sx={{ fontSize: 40 }} />} id="collapsible-nav-dropdown"> 
              <NavDropdown.Item className='navCollapsableItem' href="./projects">Projects</NavDropdown.Item>
              <NavDropdown.Item className='navCollapsableItem' href="./kvkk">Terms & Conditions</NavDropdown.Item>
              <NavDropdown.Item className='navCollapsableItem' onClick={handleCopyUserId} href="">
                Copy User ID
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className='navCollapsableItem navCollapsableItem__LogOut'  href="">
                <BriefModal
                  title="Are you leaving?"
                  style={{ color: "#fff !important" }}
                  link={<AreYouLeaving />}
                  content={<div>Logout</div>}
                />
              </NavDropdown.Item>
            </NavDropdown>
          </>:<>
            <NavDropdown title={ <PersonIcon />} id="collapsible-nav-dropdown">
              <NavDropdown.Item className='navCollapsableItem' href="">
                <BriefModal 
                  title="Sign In"
                  link={<Authenticate />} 
                  content={<div>Sign In</div>}
                />
              </NavDropdown.Item>
              <NavDropdown.Item className='navCollapsableItem' href="">Join Saniba</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className='navCollapsableItem' href="./kvkk">
                Terms & Conditions
              </NavDropdown.Item>
            </NavDropdown>
            </>  
          **/}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarSaniba;