import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import "./Dashboardbar.css"
import { ReactComponent as Logo } from "../../../assets/logo/y-w-navbar.svg"
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { loginUser, logoutUser, setLoading } from '../../../authenticate/features/userSlice';
import BriefModal from '../../modal/BriefModal/BriefModal';
import { auth } from "../../../firebase";
import { useDispatch, useSelector } from 'react-redux';
import AreYouLeaving from '../../modal/AreYouLeaving/AreYouLeaving';
import Authenticate from '../../../authenticate/auth/Authenticate';
import PersonIcon from '@mui/icons-material/Person';

function Dashboardbar(props) {
  let pathProject = true;
  if (window.location.pathname == '/projects' || window.location.pathname == '/projects/') {
    pathProject = false;
  } else { pathProject = true }

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    // 👇️ navigate to /contacts
    navigate('/projects');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          loginUser({
            uid: authUser.uid,
            username: authUser.displayName,
            email: authUser.email,
          })
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        console.log("User is not logged in.");
      }
    });
  }, []);

  const user = useSelector((state) => state.data.user.user);
  const handleCopyUserId = () => {
    const userIdToCopy = user.uid; // Assuming userId is a string
    navigator.clipboard.writeText(userIdToCopy)
      .then(() => {
        console.log('User ID copied to clipboard:', userIdToCopy);
        alert('User ID copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy user ID to clipboard:', error);
        alert('Failed to copy User ID to clipboard. Please try again.');
      });

  };
  return (
    <>
      {
        pathProject ?
          <>
            <Navbar collapseOnSelect expand="lg" className="navbar">
              <Container>
                <Logo
                  height="50"
                  className='logo'
                />
                <Navbar.Brand href="">Saniba</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                  </Nav>
                  <Nav>
                    <Nav.Link className='MainItem' href="">
                      {user ?
                        <BriefModal
                          title="Give a Brief?"
                          style={{ color: "#fff !important" }}
                          link={<Authenticate />}
                          content={<div>Give a Brief</div>}
                        />
                        :
                        <BriefModal
                          title="Give a Brief?"
                          style={{ color: "#fff !important" }}
                          link={<Authenticate />}
                          content={<div>Give a Brief</div>}
                        />
                      }

                    </Nav.Link>
                    <Nav.Link className='MainItem' href="/projects">Client
                    </Nav.Link>
                    <Nav.Link className='MainItem' eventKey={2} href="/apply">
                      Dealer
                    </Nav.Link>
                  </Nav>
                  
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </> :
          <Navbar onClick={navigateToDashboard} collapseOnSelect expand="lg" className="navbar">
            <Container>
              <Logo
                height="50"
                className='logo'
              />
              <Navbar.Brand>Saniba &gt; {props.userid}</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            </Container>
          </Navbar>
      }
    </>
  );
}

export default Dashboardbar;