import React, { useEffect, useState } from "react";
import "./Summary.css";
import Dashboardbar from "../../../../components/navigation/dashboardbar/Dashboardbar";
import { useSelector } from "react-redux";
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function Summary() {
  const [briefData, setBriefData] = useState(null);
  const currentUrl = useSelector((state) => window.location.pathname);
  const [API_URL] = useState("https://saniba-61fedb763126.herokuapp.com/");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let briefId = currentUrl.split('/').pop();
        if (briefId === "offers" || briefId === "updates" || briefId === "documents"){
          briefId = currentUrl.split('/');
          briefId = briefId[briefId.length - 2]
        }
        const response = await fetch(
          `${API_URL}briefs/${briefId}`
        );
        const data = await response.json();
        setBriefData(data.brief);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const handleProjectLoad = (briefDetail) => {
    if (briefDetail) {
      const date = new Date(briefDetail);
      const formattedDate = date.toLocaleDateString();
      return formattedDate;
    }
    return null;
  }
  
  return (
    <div className="dashboard">
      <div className="dashboard__left">
        {briefData && (
          <div className="Summary__Info">
            <h1 className="Summary__Info__Title"><span className="Summary__Info__Title__Font">Dealer | </span>&nbsp;{briefData.briefTitle}</h1>
            <p className="Summary__Info__Title"><HourglassBottomRoundedIcon /> {briefData.currentStage}</p>
            <p className="Summary__Info__Title"><CalendarMonthIcon />{handleProjectLoad(briefData.dateCreated)}</p>
            <button className='Summary__Info__Title__Button' type="submit">REQUEST SSM</button>
          </div>
        )}
      </div>
      <div className="dashboard__right">
      </div>
    </div>
  );
}

export default Summary;
