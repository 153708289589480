import React, { useState } from 'react';
import './BeDealer.css';
import { ReactComponent as Logo } from "../../assets/logo/claylogo.svg";

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    const details = {
      name,
      email,
      phone,
      message
    };

    try {
      const response = await fetch("https://saniba-61fedb763126.herokuapp.com/mails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });

      const result = await response.json();

      if (result.status === "Message Sent") {
        setStatus("Message Sent");
      } else {
        setStatus("Error Sending Message");
      }
    } catch (error) {
      setStatus("Error Sending Message");
    }
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <label>
        İsminiz:
        <input
          className='beDealerInput'
          type="text"
          name="name"
          placeholder="İsminiz"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </label>
      <br />

      <label>
        E-posta:
        <input
          className='beDealerInput'
          type="email"
          name="email"
          placeholder="E-posta"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <br />

      <label>
        Telefon:
        <input
          className='beDealerInput'
          type="tel"
          name="phone"
          placeholder="Telefon"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </label>
      <br />

      <label>
        Eklemek İstedikleriniz:
        <textarea
          className='beDealerInput'
          name="message"
          placeholder="Buraya yazın..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </label>
      <br />

      <button className='beDealerButton' type="submit">GÖNDER</button>
      {status && <p>{status}</p>}
    </form>
  );
};

const BeDealer = () => {
  return (
    <div className='applyBody'>
      <div className="centered-container">
        <div className='formTitleAlignment'>
          <Logo
            height="50"
            className='logoApply'
          />
          <h1>Siparişinizi Gönderin</h1>
        </div>
        <p>Sizinle hemen iletişime geçeceğiz.</p>
        <ContactForm />
      </div>
    </div>
  );
};

export default BeDealer;
