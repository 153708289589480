import React, { useState } from 'react';
import ReplyItem from './ReplyItem';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

const ReleaseItem = ({ release }) => {
    const [isAssetsDropdownOpen, setIsAssetsDropdownOpen] = useState(false);
    const [isRepliesDropdownOpen, setIsRepliesDropdownOpen] = useState(false);

    const toggleAssetsDropdown = () => {
        setIsAssetsDropdownOpen(prevState => !prevState);
        if (isRepliesDropdownOpen === true){
            setIsRepliesDropdownOpen(prevState => !prevState);
        }
    };
    const toggleRepliesDropdown = () => {
        setIsRepliesDropdownOpen(prevState => !prevState);
        if (isAssetsDropdownOpen === true){
            setIsAssetsDropdownOpen(prevState => !prevState);
        }
    };

    const getAssetIcon = (fileType) => {
        switch (fileType) {
            case 'zip':
                return '📦';
            case 'exe':
                return '💻';
            case 'pdf':
                return '📄';
            default:
                return '📁';
        }
    };

    return (
        <div className="release-item">
            <h2>{release.title}</h2>
            <p>Released by: {release.releasedBy}</p>
            <p>Release Date: {release.date}</p>
            <span>{release.isConfirmed ? <><TaskAltRoundedIcon />Confirmed by expert</> : <><DangerousRoundedIcon/>Not Confirmed by expert yet</>} </span>


            <h3>Fixed Issues</h3>
            <ul>
                {release.fixedIssues.map(issue => (
                    <li key={issue}>{issue}</li>
                ))}
            </ul>
            <h3 type="button" className="assets-dropdown-toggler" onClick={toggleAssetsDropdown}>Assets {isAssetsDropdownOpen ? ( <ArrowDropUpRoundedIcon sx={{ fontSize: 40 }} />) : ( <ArrowDropDownRoundedIcon sx={{ fontSize: 40 }} />)  }</h3>
            <div className='assets'>
                <div className="assets-dropdown">
                    {isAssetsDropdownOpen && (
                        <div>
                            {release.assets.map(asset => (
                                <div key={asset.name} className="asset-item">
                                    <span>{getAssetIcon(asset.type)} {asset.name}</span>
                                    <span>Size: {asset.size}</span>
                                    <span>Uploaded: {asset.uploadedDate}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <h3 className="assets-dropdown-toggler" onClick={toggleRepliesDropdown}>Replies {isAssetsDropdownOpen ? ( <ArrowDropUpRoundedIcon sx={{ fontSize: 40 }} />) : ( <ArrowDropDownRoundedIcon sx={{ fontSize: 40 }} />)  } </h3>
            <div className="replies" >
                {isRepliesDropdownOpen && (
                    <div>
                        {release.replies.map(reply => (
                            <ReplyItem key={reply.id} reply={reply} />
                        ))}

                        <span>Followers of this Release ({release.followers})</span>
                        <button>Reply to Release</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReleaseItem;
