import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./NavbarOzgurluguZorla.css"
import {ReactComponent as Logo} from "../../../../assets/logo/ozgurlugu-zorla/ozgurlugu-zorla.svg"

function NavbarOzgurluguZorla() {

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar">
      <Container>
        <Logo
          height="50"
         className='logo'
         />
        <Navbar.Brand href="">Özgürlüğü Zorla</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link className='MainItem' href="">
            </Nav.Link>
            <Nav.Link className='MainItem' href="/projects">Paylaşımlarımız
            </Nav.Link>
            <Nav.Link className='MainItem' eventKey={2} href="/apply">
              Başla
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarOzgurluguZorla;