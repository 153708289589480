import { useState } from 'react';
import "./BriefModal.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Authenticate from '../../../authenticate/auth/Authenticate';

function BriefModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span onClick={handleShow}>
        {props.content}
      </span>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='AuthModalHeader' closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='AuthModal' >{props.link}</Modal.Body>
        <Modal.Footer className='AuthModal' >
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BriefModal;