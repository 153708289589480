import { combineReducers } from 'redux';
import { userSlice } from "../features/userSlice";
import yourReducer from './reducers'; // Import your other reducers
  
const rootReducer = combineReducers({
  yourReducer,
  user: userSlice.reducer
});

export default rootReducer;
