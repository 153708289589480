import React, { useEffect, useState } from "react";
import "./Documents.css";
import Sidenav from "../../../components/navigation/sidenav/Sidenav";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Summary from "../components/Summary/Summary";
import { Col, Container, Row } from "react-bootstrap";



function Documents() {
  const { projectType, projectId } = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const navigate = useNavigate();
  const user = useSelector((state) => state.data.user.user);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `https://saniba-61fedb763126.herokuapp.com/briefs/${projectId}`
        );
        const data = await response.json();
        setProjectDetails(data);
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  let isUserAuthorized;

  useEffect(() => {
    // Check if the user is authorized to access the project
    const userIdControl = user.uid;

    if (projectDetails.brief && projectDetails.brief.userId !== undefined) {
      isUserAuthorized =
        projectDetails.brief.userId === userIdControl ||
        (projectDetails.brief.coworkerId && projectDetails.brief.coworkerId.includes(userIdControl));

      // If not authorized, redirect to projects page
      if (!isUserAuthorized) {
      }
    }
  }, [projectDetails, navigate, user]);

  return (
    <div className="projectPage">
      <div className="projectPage__navWraper">
        <Sidenav projectId={projectId} projectType={projectType} />
      </div>
      <div className="projectPage__Content">
        <DocumentsDetails />
      </div>
    </div>
  );
}

function DocumentsDetails() {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} sm={12}>
            <Summary />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Documents;
