import React from "react";
import "./WelcomeCards.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function WelcomeCards() {
    return (
      <Container className="CardDisplayContainer">
      <Row className="CardDisplay">
        <Col lg={4} sm={12}>
            <div className="Card1">
              <div className="Card1__Content">
                <div className="Card1__Content__Title">START WITH BRIEF</div>
                <div className="Card1__Content__Paragraph" style={{width: '80%', height: 180, color: '#171717', fontSize: 30}}>Dive in to specialized brief for best experince.</div>
              </div>
              <img className="CardDisplay__Image__1" src={require('../../../assets/images/briefimage_white.png')} />
            </div>
            <div onClick={()=>this.addClick("SM")} className="CardButton WelcomePageButton">
              <div className="CardButton__Text">CHOOSE</div>
            </div>
        </Col>
        <Col lg={4} sm={12}>
            <div className="Card2">
              <div style={{left: 60, top: 53, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                <div className="Card2__Content__Title">GET PLACE IN SANIBA</div>
                <div className="Card1__Content__Paragraph" style={{width: '80%', height: 180, color: '#ffffff', fontSize: 30}}>Fill a form in Saniba and start your business all over.</div>
              </div>
              <img className="CardDisplay__Image__2" src={require('../../../assets/images/signupimage_white.png')} />
            </div>
            <div onClick={()=>this.addClick("SM")} className="CardButton WelcomePageButton">
              <div className="CardButton__Text">CHOOSE</div>
            </div>
        </Col>
        <Col lg={4} sm={12}>
            <div className="Card3">
              <div className="Card3__Alignment">
                <div className="Card3__1">
                  <div style={{left: 60, top: 53, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',  display: 'inline-flex'}}>
                    <div className="Card2__Content__Title">SEE DEALERS</div>
                    <div className="Card1__Content__Paragraph" style={{width: '80%', height: 85, color: 'white', fontSize: 30}}>Giant Community with benefits</div>
                  </div>
                </div>
                <div className="Card3__2">
                  <div style={{left: 60, top: 43, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div className="Card2__Content__Title">BIG GIGS</div>
                    <div className="Card1__Content__Paragraph" style={{width: '80%', height: 132, color: 'white', fontSize: 30}}>See previous products done by Saniba</div>
                  </div>
                </div>
                <div onClick={()=>this.addClick("SM")} className="CardButton WelcomePageButton">
                  <div className="CardButton__Text">CHOOSE</div>
                </div>
              </div>
            </div>
        </Col>
      </Row>
    </Container>

    );
  }
  
  export default WelcomeCards;
  