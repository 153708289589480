import React from 'react';

const ReplyItem = ({ reply }) => {
    const getTagColor = (tag) => {
        switch (tag) {
            case 'dealer':
                return 'blue';
            case 'expert':
                return 'green';
            case 'co-worker':
                return 'orange';
            case 'me':
                return 'purple';
            default:
                return 'gray';
        }
    };

    return (
        <div className="reply-item">
            <img src={reply.image} alt={reply.name} className="reply-image" />
            <span className="reply-name">{reply.name}</span>
            <span className="reply-tag" style={{ color: getTagColor(reply.tag) }}>{reply.tag}</span>
            <p className="reply-content">{reply.content}</p>
            <span className="reply-date">{reply.date}</span>
        </div>
    );
};

export default ReplyItem;
