import React, { useEffect, useState } from "react";
import "./TaskProvideInformationModalContent.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { auth } from "../../../../firebase";
import { updateProfile } from "firebase/auth";
import axios from 'axios';
import { useSelector } from "react-redux";

const TaskProvideInformationModalContent = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Update the user's profile with the new username
      await updateProfile(auth.currentUser, { displayName: username });

      // Redirect or perform any other action after successful update
      navigate('/dashboard'); // Change the route as needed
    } catch (error) {
      console.error('Error updating username:', error.message);
      // Handle error, e.g., show an error message to the user
    }
  };

  /*CHATGPT */
  const user = useSelector((state) => state.data.user.user);
  const uid = user.uid;

  const [formData, setFormData] = useState({
    UserIndustry: '',
    UserPostion: '',
    UserLanguage: ''
  });

  useEffect(() => {
    // Fetch the existing single brief data
    axios.get(`https://saniba-61fedb763126.herokuapp.com/singleBriefs/${uid}`)
      .then(response => {
        if (response.data.singleBrief) {
          setFormData(response.data.singleBrief);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the Single Brief data!', error);
      });
  }, [uid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    axios.patch(`https://saniba-61fedb763126.herokuapp.com/singleBriefs/${uid}`, formData)
      .then(response => {
        console.log('Single Brief updated successfully:', response.data);
      })
      .catch(error => {
        console.error('There was an error updating the Single Brief!', error);
      });
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <button type="submit">Update Username</button>
          </form>
        </Col>
      </Row><Row>
        <Col sm={12}>
          <h2>Update Single Brief</h2>
          <form onSubmit={handleSubmit2}>
            <div>
              <label htmlFor="UserIndustry">User Industry:</label>
              <select
                id="UserIndustry"
                name="UserIndustry"
                value={formData.UserIndustry}
                onChange={handleInputChange}
              >
                <option value="">Select User Industry</option>
                <option value="Tech">Tech</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
              </select>
            </div>
            <div>
              <label htmlFor="UserPostion">User Position:</label>
              <select
                id="UserPostion"
                name="UserPostion"
                value={formData.UserPostion}
                onChange={handleInputChange}
              >
                <option value="">Select User Position</option>
                <option value="Manager">Manager</option>
                <option value="Developer">Developer</option>
                <option value="Designer">Designer</option>
              </select>
            </div>
            <div>
              <label htmlFor="UserLanguage">User Language:</label>
              <select
                id="UserLanguage"
                name="UserLanguage"
                value={formData.UserLanguage}
                onChange={handleInputChange}
              >
                <option value="">Select User Language</option>
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
              </select>
            </div>
            <button type="submit">Update Single Brief</button>
          </form>
        </Col>
      </Row>

    </Container>
  );
};

export default TaskProvideInformationModalContent;
