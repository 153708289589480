const OpenAI = require("openai");

const secretKey = "sk-proj-cXg0XzyZ2LEtUk2lAz1dT3BlbkFJ5qV513w7tkaun8m32p8D";
const openai = new OpenAI({
  apiKey: secretKey,
  dangerouslyAllowBrowser: true
});

export async function getQuery({ conversationHistory }) {
  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-1106",
      messages: conversationHistory,
    });

    const responseText = completion.choices[0].message.content;
    return {
      quizResult: responseText,
    };
  } catch (error) {
    console.error("Error generating quiz question:", error);
    throw new Error("An error occurred while generating the quiz question.");
  }
}

export const params = {
  question: { type: "string" },
  mainChoose: { type: "string" },
  userName: { type: "string" },
  userIndustry: { type: "string" },
  userPostion: { type: "string" },
  userLanguage: { type: "string" }
};
