import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, RecaptchaVerifier } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyAww8wXTucRgvvBiOxwDk81oQRQTft3L4A",
  authDomain: "sanibareact.firebaseapp.com",
  projectId: "sanibareact",
  storageBucket: "sanibareact.appspot.com",
  messagingSenderId: "484519459781",
  appId: "1:484519459781:web:02cb6f22338864f36729ac",
  measurementId: "G-462V30JYYW"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

//Recaptcha
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const auth = getAuth(app);
export default getFirestore();