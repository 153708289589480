import React, { useState, useRef, useEffect } from "react";
import "./WelcomeProducts.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "../../../assets/logo/w-b-yellow-bg.svg"

function WelcomeProducts() {
    const [selectedLogoIndex, setSelectedLogoIndex] = useState(0);

    const brands = [
        {
            logo: '/path-to-logo/logo1.png',
            title: 'Brand 1',
            class: 'ProductCard1',
            description: 'Description for Brand 1',
            content: [
                {
                    image: '/path-to-image/image1.png',
                    title: 'Feature 1',
                    description: 'Feature 1 description for Brand 1',
                },
                {
                    image: '/path-to-image/image2.png',
                    title: 'Feature 2',
                    description: 'Feature 2 description for Brand 1',
                },
                {
                    image: '/path-to-image/image3.png',
                    title: 'Feature 3',
                    description: 'Feature 3 description for Brand 1',
                },
            ],
        },
        {
            logo: '/path-to-logo/logo2.png',
            title: 'Brand 2',
            class: 'ProductCard2',
            description: 'Description for Brand 2',
            content: [
                {
                    image: '/path-to-image/image1.png',
                    title: 'Feature 1',
                    description: 'Feature 1 description for Brand 2',
                },
                {
                    image: '/path-to-image/image2.png',
                    title: 'Feature 2',
                    description: 'Feature 2 description for Brand 2',
                },
                {
                    image: '/path-to-image/image3.png',
                    title: 'Feature 3',
                    description: 'Feature 3 description for Brand 2',
                },
            ],
        },
        {
            logo: '/path-to-logo/logo3.png',
            title: 'Brand 3',
            class: 'ProductCard3',
            description: 'Description for Brand 3',
            content: [
                {
                    image: '/path-to-image/image1.png',
                    title: 'Feature 1',
                    description: 'Feature 1 description for Brand 3',
                },
                {
                    image: '/path-to-image/image2.png',
                    title: 'Feature 2',
                    description: 'Feature 2 description for Brand 3',
                },
                {
                    image: '/path-to-image/image3.png',
                    title: 'Feature 3',
                    description: 'Feature 3 description for Brand 3',
                },
            ],
        },
    ];

    const handleLeftClick = () => {
        setSelectedLogoIndex((prevIndex) =>
            prevIndex === 0 ? brands.length - 1 : prevIndex - 1
        );
    };

    const handleRightClick = () => {
        setSelectedLogoIndex((prevIndex) =>
            prevIndex === brands.length - 1 ? 0 : prevIndex + 1
        );
    };


    return (
        <Container>
            <Row>
                <Col md={4} className={brands[selectedLogoIndex].class + " brand-left-part d-flex flex-column align-items-center"}>
                    <Logo
                        className="brand-logo-top brand-presentation-logo"
                        src={Logo}
                        alt="Saniba Logo" />
                    <div className="brand-title mt-4">
                        <h2>{brands[selectedLogoIndex].title}</h2>
                    </div>
                    <div className="brand-description mt-3 text-center">
                        <p>{brands[selectedLogoIndex].description}</p>
                    </div>
                    <div className="brand-carousel mt-auto d-flex justify-content-center align-items-center">
                        <button className="btn btn-link" onClick={handleLeftClick}>&lt;</button>
                        <div className="logo-middle">
                            <img src={brands[selectedLogoIndex].logo} alt="Selected Brand Logo" width="100" />
                        </div>
                        <button className="btn btn-link" onClick={handleRightClick}>&gt;</button>
                    </div>
                </Col>


                <Col md={8} className="brand-right-part d-flex">
                    {brands[selectedLogoIndex].content.map((item, index) => (
                        <div key={index} className="brand-content text-center mx-3">
                            <img src={item.image} alt={item.title} className="img-fluid" />
                            <h3 className="mt-3">{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}

export default WelcomeProducts;