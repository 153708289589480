import React from "react";
import "./ProjectorRouter.css";
import Briefbar from "../../components/navigation/briefbar/Briefbar";
import ProjectsList from "./ProjectsList/ProjectsList";
import { Routes, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
import DynamicBrief from "../briefapi/DynamicBrief";

function ProjectorRouter() {

  let pathProject = true;
  if (window.location.pathname === '/projects' || window.location.pathname === '/projects/') {
    pathProject = false;
  } else {
    pathProject = true;
  }

  const user = useSelector((state) => state.data.user.user);

  return (
    <>
      <Briefbar userid={user.username} />
      <div className="SelectionScale">{
        pathProject ?
          <>
            <DynamicBrief />
          </>
          :
          <>
            <Routes>
              <Route path="/" element={<ProjectsList userid={user.uid} username={user.username} />} />
              <Route path="" element={<ProjectsList userid={user.uid} username={user.username} />} />
            </Routes>
          </>
      }
      </div>
    </>
  );
}
export default ProjectorRouter;
