import React, { useEffect, useState } from "react";
import "./Offers.css";
import Sidenav from "../../../components/navigation/sidenav/Sidenav";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Summary from "../components/Summary/Summary";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "../../../assets/logo/w-b-yellow-bg.svg"
import PlaceIcon from '@mui/icons-material/Place';

function Offers() {
  const { projectType, projectId } = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const navigate = useNavigate();
  const user = useSelector((state) => state.data.user.user);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `https://saniba-61fedb763126.herokuapp.com/briefs/${projectId}`
        );
        const data = await response.json();
        setProjectDetails(data);
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  let isUserAuthorized;
  return (
    <div className="projectPage">
      <div className="projectPage__navWraper">
        <Sidenav projectId={projectId} projectType={projectType} />
      </div>
      <div className="projectPage__Content">
        <OffersDetails />
      </div>
    </div>
  );
}

function OffersDetails() {
  const [hoveredDiv, setHoveredDiv] = useState(null);
  const [clickedDiv, setClickedDiv] = useState(null);
  const [offers, setOffers] = useState([]);
  const activeProjectId = useSelector((state) => state.activeProject.data);

  useEffect(() => {
    fetch('https://saniba-61fedb763126.herokuapp.com/offers')
      .then(response => response.json())
      .then(data => {
        // Filter offers by BriefId
        const filteredOffers = data.offers.filter(offer => offer.BriefId === activeProjectId);
        setOffers(filteredOffers);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);



  const handleMouseEnter = (id) => {
    setHoveredDiv(id);
  };

  const handleMouseLeave = () => {
    setHoveredDiv(null);
  };

  const handleClick = (id) => {
    setClickedDiv(id);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} sm={12}>
            <Summary />
          </Col>
          {offers.map((offer, index) => (
            <>
              <Col
                lg={10}
                sm={12}
                onMouseEnter={() => handleMouseEnter(index + 1)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index + 1)}
              >
                <div className="Dashboard__Card__Offer">
                  <div className="Dashboard__Card__Content">
                    <div className="Dashboard__Card__Title__Offer__Dealer">
                      <Logo
                        src={Logo}
                        alt="Saniba Logo"
                        style={{ width: "2.6rem", borderRadius: "100%", height: "100%" }}
                      />
                      <p className="Dashboard__Card__Title__Offer">{offer.DealerName}</p>
                    </div>
                    <div className="Dashboard__Card__Content__Inner">
                      <div>
                        <div className="Dashboard__Card__Content__Title__Offer">Technical Support Engineer</div>
                        <div className="Dashboard__Card__Offer__Content__IconText">
                          <PlaceIcon sx={{ fontSize: 30, color: 'var(--white)' }} />
                          <p>Estimated Deadline: {offer.EstimatedDeadline}Offer Cost: {offer.OfferCost}</p>
                        </div>
                        <div className="Dashboard__Card__Content__Paragraph__Offer">{offer.OfferDescription}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={2}
                onMouseEnter={() => handleMouseEnter(index + 1)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index + 1)}
              >
                <div
                  className={hoveredDiv === index + 1 ? "OfferButtonHover" : "OfferButton"}>
                  <div
                    className={"OfferButton__SLidingButtons"}
                    style={{ marginTop: 56 }}
                  >
                    <h3 className="OfferButton__SLidingButtons__Text">CLIENT</h3>
                  </div>
                  <div
                    className={"OfferButton__SLidingButtons"}
                    style={{ marginLeft: 32, marginTop: 56 }}
                  >
                    <h3 className="OfferButton__SLidingButtons__Text">DEALER</h3>
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Offers;
