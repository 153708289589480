import React, { useEffect, useState } from "react";
import "./ProjectsList.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ReactComponent as Logo } from "../../../assets/logo/y-w-black-bg.svg";
import { useNavigate } from 'react-router-dom';
import BriefModal from "../../../components/modal/BriefModal/BriefModal";
import TaskProvideInformationModalContent from "./TaskProvideInformationModalContent/TaskProvideInformationModalContent";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { useDispatch } from "react-redux";
import { updateActiveProject, toggleProjectSelected } from '../../../authenticate/features/activeProjectSlice';

const ProjectsList = ({ userid, username }) => {
  const [briefFromAPI, setBriefFromAPI] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const API_URL = "https://saniba-61fedb763126.herokuapp.com/";

  useEffect(() => {
    refreshBriefFromAPI();
  }, [userid]);

  const refreshBriefFromAPI = async () => {
    try {
      const response = await fetch(API_URL + "briefs");
      const data = await response.json();
      const userBriefs = data.briefs.filter((brief) => brief.userId === userid);
      setBriefFromAPI(userBriefs);
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  const handleProjectClick = (briefDetail) => {
    dispatch(updateActiveProject(briefDetail._id));
    navigate(`/projects/dashboard/${briefDetail.sanibaProjectType}/${briefDetail._id}`);
  };

  const handleProjectLoad = (briefDetail) => {
    if (briefDetail.dateCreated) {
      const date = new Date(briefDetail.dateCreated);
      const formattedDate = date.toLocaleDateString();
      return formattedDate;
    }
    return null;
  }

  const handleProjectAdd = () => {
    window.history.pushState(null, "", `/projects/brief/create`);
    window.location.reload();
  }

  const handleProjectAddWithAi = () => {
    window.history.pushState(null, "", `/projects/brief/createai`);
    window.location.reload();
  }

  const returnWelcomepage = async () => {
    navigate('/');
  }
  let isUsernameDefined = username !== null;

  return (
    <>
      <Container>
        <Row>
          {isUsernameDefined ?
            <Col lg={4} sm={12}>
              <div className="CreateProject" >
                <div className="CreateProjectAlignment">
                  <div style={{ color: 'white', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>Add Project</div>
                  <div onClick={() => handleProjectAdd()} className="CreateProjectButton">
                    <AddRoundedIcon sx={{ fontSize: 60 }} />
                  </div>
                </div>
                <img style={{ width: 360, height: 226.30, position: "absolute", bottom: 0, zIndex: "-1" }} src={require('../../../assets/images/addproject_white.png')} />
              </div>
            </Col>
            :
            <Col lg={4} sm={12}>
              <div className="CreateProject" >
                <div className="CreateProjectAlignment">
                  <div style={{ color: 'white', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word', textAlign: 'center' }}>Add Contact Information</div>
                  <BriefModal
                    title="Add Information"
                    link={<TaskProvideInformationModalContent />}
                    content={
                      <div className="CreateProjectButton">
                        <AlternateEmailRoundedIcon sx={{ fontSize: 60 }} />
                      </div>
                    }
                  />
                </div>
                <img style={{ width: 260, height: 200, position: "absolute", bottom: 0, zIndex: "-1" }} src={require('../../../assets/images/contactdoneimage_white.png')} />
              </div>
            </Col>
          }
          {isUsernameDefined ?
            <Col lg={8} sm={12}>
              <Container>
                <Row className="projectsCardGrid">
                  {briefFromAPI &&
                    briefFromAPI.map((briefDetail) => (
                      <Col onClick={() => handleProjectClick(briefDetail)} lg={6} sm={12} className={briefDetail.sanibaProjectType} key={briefDetail._id}>
                        <div className="projectsCard__Col">
                          <div className="projectsCard__Content" >
                            <Logo height="50" className='logo projectsCard__Logo' />
                            <div style={{ color: 'white', fontSize: 24, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>
                              <a>{briefDetail.sanibaProjectType} | {briefDetail.briefTitle}</a>
                            </div>
                          </div>
                          <div className="projectsCard__Info">
                            <CalendarMonthIcon className="projectsCard__Info__Icon" /><p className="projectsCard__Info__Text">{handleProjectLoad(briefDetail)}</p>
                          </div>
                          <div className="projectsCard__Info">
                            <p className="projectsCard__Info__Text">{briefDetail.dealerId}</p>
                          </div>
                          <div className="projectsCard__Info">
                            <p className="projectsCard__Info__Text">{briefDetail.currentStage}</p>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </Container>
            </Col>
            :
            <Col lg={8} sm={12}>
              <div style={{ margin: '2rem 2rem' }}>
                <div style={{ color: 'white', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', letterSpacing: 0.84, wordWrap: 'break-word' }}>Add information about you</div>
                <div style={{ color: 'white', fontSize: 24, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', letterSpacing: 0.84, wordWrap: 'break-word', paddingTop: '1rem' }}>Provide your name and email, enabling personalized communication for a tailored and transparent freelance experience from start to finish.</div>
              </div>
            </Col>
          }
          <Col lg={4} sm={12}>
            <div className="CreateProject" style={{ marginTop: "2rem" }} >
              <div className="CreateProjectAlignment">
                <div style={{ color: 'white', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>View Updates</div>
                <BriefModal
                  title="Give a Brief"
                  link={<TaskProvideInformationModalContent />}
                  content={
                    <div className="CreateProjectButton">
                      <TaskAltRoundedIcon sx={{ fontSize: 60 }} />
                    </div>
                  }
                />
              </div>
              <img style={{ width: 260, height: 200, position: "absolute", bottom: 0, zIndex: "-1" }} src={require('../../../assets/images/contactdoneimage_white.png')} />
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="CreateProject" style={{ marginTop: "2rem" }} >
              <div className="CreateProjectAlignment">
                <div style={{ color: 'white', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>Add Project w/ OpenAI</div>
                <div onClick={() => handleProjectAddWithAi()} className="CreateProjectButton">
                  <AddRoundedIcon sx={{ fontSize: 60 }} />
                </div>
              </div>
              <img style={{ width: 260, height: 200, position: "absolute", bottom: 0, zIndex: "-1" }} src={require('../../../assets/images/contactdoneimage_white.png')} />
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div>
            </div>
          </Col>
        </Row>
      </Container>
      <div onClick={() => returnWelcomepage()} className="returnMainPage">
        <ArrowBackIcon sx={{ fontSize: 52 }} className="returnMainPage__ArrowBack" />
        <h4 className="SelectionDeleteAlignment__Text">Back to Welcome Page</h4>
      </div>
    </>
  );
}
export default ProjectsList;