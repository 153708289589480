import { createSlice } from '@reduxjs/toolkit';

const activeProjectSlice = createSlice({
  name: 'activeProject',
  initialState: {
    data: '',
    isProjectSelected: false,
  },
  reducers: {
    updateActiveProject(state, action) {
      state.data = action.payload;
    },
    toggleProjectSelected(state) {
      state.isProjectSelected = !state.isProjectSelected;
    },
  },
});

export const { updateActiveProject, toggleProjectSelected } = activeProjectSlice.actions;
export default activeProjectSlice.reducer;
