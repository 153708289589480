import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { getQuery } from "./getQuery";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

function SingleBrief() {
    const singleBriefId = useSelector((state) => state.singleBrief.data);

    const [inputTopic, setInputTopic] = useState(""); // For user's input
    const [questionData, setQuestionData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [isBriefStarted, setIsBriefStarted] = useState(false);
    const [selectedOptionBody, setSelectedOptionBody] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [conversationHistory, setConversationHistory] = useState([]);

    const styles = {
        container: {
            padding: "20px",
            maxWidth: "600px",
            margin: "auto",
            fontFamily: "Arial, sans-serif",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", // Added shadow for depth
            borderRadius: "10px", // Rounded corners
            backgroundColor: "white", // Ensuring the container is always white
        },
        input: {
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ddd", // Subtle border
            borderRadius: "4px", // Slightly rounded corners
        },
        button: {
            width: "100%",
            padding: "10px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s", // Smooth transition for hover effect
        },
        response: {
            marginTop: "20px",
            textAlign: "center",
            backgroundColor: "white",
            color: "black",
            padding: "10px", // Added padding
            borderRadius: "4px", // Slightly rounded corners
        },
        error: {
            color: "red",
            textAlign: "center",
            marginTop: "10px",
        },
        option: {
            cursor: "pointer",
            padding: "10px",
            margin: "5px 0",
            borderRadius: "4px",
            border: "1px solid #ddd", // Subtle border
            transition: "background-color 0.3s", // Smooth transition for hover effect
        },
        topic: {
            fontSize: "24px", // Larger font size
            fontWeight: "bold", // Bold font
            textAlign: "center",
            margin: "20px 0", // Add some margin for spacing
        },
        option: {
            cursor: "pointer",
            padding: "15px 10px", // Increased padding for better spacing
            margin: "10px 0", // Increased margin for better spacing
            borderRadius: "4px",
            border: "1px solid #ddd",
            transition: "background-color 0.3s, transform 0.3s", // Smooth transition for hover effect
            transform: "scale(1)", // Default scale
            backgroundColor: "white",
        },
        optionHover: {
            transform: "scale(1.02)", // Slightly scale up on hover
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Add shadow on hover
        },
        selectedOption: {
            border: "3px solid #ffd700", // Gold border for the selected option
        },
    };

    async function submitting(selectedOptionInFunc) {
        try {
            setIsSubmitting(true);
            let inputValue;
            isBriefStarted ? inputValue = selectedOptionInFunc : inputValue = inputTopic;

            const newMessage = {
                role: "user",
                content: `Provide a question with three to nine possible options about: ${inputValue}`
            };

            // Append new message to conversation history
            const updatedConversationHistory = [...conversationHistory, newMessage];

            const response = await getQuery({ conversationHistory: updatedConversationHistory });

            const parsedResponse = JSON.parse(response.quizResult);

            // Add assistant's response to the conversation history
            updatedConversationHistory.push({
                role: "assistant",
                content: response.quizResult
            });

            setConversationHistory(updatedConversationHistory);
            setQuestionData(parsedResponse);
            setSelectedOption(null);
            setIsBriefStarted(true);
            setInputTopic(""); // Clear input after submission
            document.body.style.backgroundColor = "white"; // Reset background color
            setSubmitError(null);
        } catch (error) {
            console.error("Error submitting question:", error);
            setSubmitError("An error occurred while submitting the question.");
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleSubmit = async () => {
        if (isBriefStarted === false) {
            submitting();
        } else {
            submitting(selectedOptionBody);
        }
    };

    const handleOptionClick = (optionKey) => {
        const correct = questionData.options[optionKey].isItCorrect;
        setSelectedOption(optionKey);
    };

    const handleChangeAnswer = (optionBody) => {
        setSelectedOptionBody(optionBody);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://saniba-61fedb763126.herokuapp.com/singleBriefs/' + singleBriefId);
                setUserData(response.data.singleBrief);

                // Initialize conversation history with the system message and training messages
                const initialSystemMessage = {
                    role: "system",
                    content:
                        `You are a helpful assistant that generate freelancer job listing filter questions based on a topic. You need to find the best match or matches the user desires. User name is: ${response.data.singleBrief.UserName}. User priority is: ${response.data.singleBrief.MainChoose}. User working in this industry: ${response.data.singleBrief.UserIndustry}. User position is ${response.data.singleBrief.UserPostion}. User language is: ${response.data.singleBrief.UserLanguage}Provide your answer in JSON structure like this {"topic": "<The topic of the quiz>", "question": "<The quiz question you generate>", "options": {"option1": {"body": "<Plausible option 1>"}, "option2": {"body": "<Plausible option 2>"}, "option3": {"body": "<Plausible option 3>"}, "option4": {"body": "<Plausible option 4>"}, "option5": {"body": "<Plausible option 5>"}}}`
                };

                const trainingMessages = [
                    {
                        role: "user",
                        content:
                            "Provide a questions with three to nine possible options about: Graphic Design",
                    },
                    {
                        role: "assistant",
                        content:
                            '{"topic": "Graphic Design", "question": "In which field do you need graphic design?", "options": {"option1": {"body": "Cover design"}, "option2": {"body": "Content design"}, "option3": {"body": "Brand design"}}}',
                    },
                    {
                        role: "user",
                        content:
                            "Provide a questions with three to nine possible options about: Graphic Design > Cover design",
                    },
                    {
                        role: "assistant",
                        content:
                            '{"topic": "Cover design", "question": "Are you planning to present design digitally or physically?", "options": {"option1": {"body": "Digital"}, "option2": {"body": "Physical"}, "option3": {"body": "I haven\'t decide it yet."}}}',
                    }
                ];

                setConversationHistory([initialSystemMessage, ...trainingMessages]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    },);


    const handleMouseOver = (e, key) => {
        if (selectedOption !== key) {
            e.currentTarget.style.transform = styles.optionHover.transform;
            e.currentTarget.style.boxShadow = styles.optionHover.boxShadow;
        }
    };

    const handleMouseOut = (e, key) => {
        if (selectedOption !== key) {
            e.currentTarget.style.transform = styles.option.transform;
            e.currentTarget.style.boxShadow = "none";
        }
    };

    return (
        <Container>
            <Row>
                <Col lg={12}>
                    <input
                        type="text"
                        placeholder="Enter a topic"
                        value={inputTopic}
                        onChange={(e) => setInputTopic(e.target.value)}
                        style={styles.input}
                        disabled={isSubmitting}
                    />
                    <button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        style={styles.button}
                    >
                        {isSubmitting ? "Generating quiz question..." : "Get quiz question"}
                    </button>
                    {submitError && <div style={styles.error}>{submitError}</div>}

                    {
                        questionData && (
                            <>
                                {isBriefStarted ?
                                    <Row>
                                        <Col lg={12}>
                                            <div className="CardDisplayProfiles__Content__Title">{questionData.topic}</div>
                                            <div className="CardDisplayProfiles__Content__Description">
                                                <p>{questionData.question}</p>
                                                <Col sm={4} >

                                                    {Object.keys(questionData.options).map((key) => (
                                                        <>
                                                            <div className="OptionCardSub"
                                                                key={key}
                                                                onClick={() => { handleOptionClick(key); handleChangeAnswer(questionData.options[key].body) }}

                                                                onMouseOver={(e) => handleMouseOver(e, key)}
                                                                onMouseOut={(e) => handleMouseOut(e, key)}
                                                            >
                                                                <div style={{ alignSelf: "stretch", height: 360, flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", gap: 24, display: "flex" }}>
                                                                    <div style={{ width: 644, justifyContent: "flex-start", alignItems: "center", gap: 16, display: "inline-flex" }}>
                                                                        <div style={{ flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: 8, display: "inline-flex" }}>
                                                                            <div style={{ color: "white", fontSize: 33.67, fontFamily: "Plus Jakarta Sans", fontWeight: "800", letterSpacing: 0.84, wordWrap: "break-word" }}>{questionData.options[key].body}</div>
                                                                            <div style={{ width: 361, color: "white", fontSize: 25.90, fontFamily: "Plus Jakarta Sans", fontWeight: "600", wordWrap: "break-word" }}>StepDescription</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="CardButton">
                                                                        <div className="CardButton__Text" >CONTINUE</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </Col>

                                            </div>

                                        </Col>
                                    </Row>
                                    :
                                    (
                                        <h1>UNABLE TO FETCH</h1>
                                    )
                                }
                            </>
                        )
                    }

                </Col>
            </Row>
        </Container>
    );
}

export default SingleBrief;
