import React, { useState } from 'react';
import './OzgurluguZorlaForm.css';

const OzgurluguZorlaForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setStatus("Sending...");

        const details = {
            name,
            email,
            question1,
            question2
        };

        try {
            const response = await fetch("https://saniba-61fedb763126.herokuapp.com/ozgurluguzorla", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(details),
            });

            const result = await response.json();

            if (result.status === "Message Sent") {
                setStatus("Message Sent");
            } else {
                setStatus("Error Sending Message");
            }
        } catch (error) {
            setStatus("Error Sending Message");
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmitForm}>
                <input type="text" name="name" placeholder="Name" value={name}
                    onChange={(e) => setName(e.target.value)} required />
                <input type="email" name="email" placeholder="Email" value={email} 
                    onChange={(e) => setEmail(e.target.value)} required />
                <textarea name="question1" placeholder="Question 1" value={question1} 
                    onChange={(e) => setQuestion1(e.target.value)} required></textarea>
                <textarea name="question2" placeholder="Question 2" value={question2} 
                    onChange={(e) => setQuestion2(e.target.value)} required></textarea>
                <button type="submit">Submit</button>
                {status && <p>{status}</p>}
            </form>
        </div>

    );
};

export default OzgurluguZorlaForm;
