import React, { useState } from "react";
import "./WelcomeProfiles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import MarkChatUnreadRoundedIcon from '@mui/icons-material/MarkChatUnreadRounded';
import {ReactComponent as Logo} from "../../../assets/logo/claylogo.svg"

function WelcomeProfiles() {
    const [isClientActive, setClientActive] = useState(true);

    const handleSwitcherClick = () => {
      setClientActive(!isClientActive);
    };

    return (
      <Container className="CardDisplayProfilesContainer">
      <Row className="CardDisplayProfiles">
        <Col sm={11}>
          <div className="CardProfiles">
            <Container>
              <Row>
                <Col sm={3}>
                  <img className="CardDisplayProfiles__Image__Profiles" src={require('../../../assets/images/coffeimage_white.png')} />
                </Col>
                <Col sm={9} className="CardDisplayProfiles__Text__Profiles">
                  <div style={{ left: 60, top: 53, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                    <div style={{ color: '#2C3811', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>It’s a comfort zone for you.</div>
                    <div style={{ width: '80%', height: 180, color: '#171717', fontSize: 28, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word' }}>
                      {isClientActive ?
                       "Client ipsum Client sit Client, Client adipiscing Client. Vestibulum Client ut Client at efficitur. Client erat odio, Client eget iaculis non." 
                       : 
                       "Dealer ipsum dolor Dealer amet, Dealer Dealer elit. Vestibulum vehicula ut Dealer at Dealer. Nullam erat Dealer, Dealer Dealer iaculis non."
                       }
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col sm={1}>
          <div className="CardProfiles__Switcher">
            <div
              className={isClientActive ? "CardProfiles__Switcher__Button__Active" : "CardProfiles__Switcher__Button"}
              onClick={handleSwitcherClick}
            >
              <h3 className="CardProfiles__Switcher__Text">CLIENT</h3>
            </div>
            <div
              className={isClientActive ? "CardProfiles__Switcher__Button" : "CardProfiles__Switcher__Button__Active"}
              onClick={handleSwitcherClick}
              style={{ marginTop: 32 }}
            >
              <h3 className="CardProfiles__Switcher__Text">DEALER</h3>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

    );
  }
  
  export default WelcomeProfiles;
  