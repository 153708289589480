import React, { useEffect, useState } from "react";
import "./Updates.css";
import Sidenav from "../../../components/navigation/sidenav/Sidenav";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Summary from "../../projects/components/Summary/Summary";
import ReleaseList from "./components/ReleaseList";
import { Col, Container, Row } from "react-bootstrap";
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import BookmarkAddRoundedIcon from '@mui/icons-material/BookmarkAddRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

function Updates() {
  const { projectType, projectId } = useParams();

  return (
    <div className="projectPage">
      <div className="projectPage__navWraper">
        <Sidenav projectId={projectId} projectType={projectType} />
      </div>
      <div className="projectPage__Content">
        <UpdatesDetails />
      </div>
    </div>
  );
}

function UpdatesDetails() {
  const [hoveredDiv, setHoveredDiv] = useState(null);
  const [clickedDiv, setClickedDiv] = useState(null);
  const [offers, setOffers] = useState([]);

  const activeProjectId = useSelector((state) => state.activeProject.data);

  useEffect(() => {
    fetch('https://saniba-61fedb763126.herokuapp.com/offers')
      .then(response => response.json())
      .then(data => {
        // Filter offers by BriefId
        const filteredOffers = data.offers.filter(offer => offer.BriefId === activeProjectId);
        setOffers(filteredOffers);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const releases = [
    {
      id: 1,
      title: 'Release v1.0',
      releasedBy: 'John Doe',
      date: '2024-06-20',
      fixedIssues: ['Issue 1', 'Issue 2'],
      assets: [
        { name: 'file.zip', type: 'zip', size: '10MB', uploadedDate: '2024-06-19' },
        { name: 'installer.exe', type: 'exe', size: '50MB', uploadedDate: '2024-06-19' },
      ],
      followers: 120,
      isConfirmed: true,
      replies: [
        { id: 1, name: 'Jane Smith', tag: 'expert', image: 'path/to/image.jpg', content: 'Great release!', date: '2024-06-20' },
      ],
    },
  ];

  const handleMouseEnter = (id) => {
    setHoveredDiv(id);
  };

  const handleMouseLeave = () => {
    setHoveredDiv(null);
  };

  const handleClick = (id) => {
    setClickedDiv(id);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} sm={12}>
            <Summary />
          </Col>
          {offers.map((offer, index) => (
            <>
              <Col
                lg={10}
                onMouseEnter={() => handleMouseEnter(index + 1)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index + 1)}
              >
                <div>
                  <ReleaseList
                    releases={releases}
                  />
                </div>
              </Col>
              <Col
                lg={2}
                onMouseEnter={() => handleMouseEnter(index + 1)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index + 1)}
              >
                <div
                  className={hoveredDiv === index + 1 ? "UpdateButtonHover" : "UpdateButton"}>
                  <div
                    className={"UpdateButton__SLidingButtons"}
                    style={{ marginTop: 40 }}
                  >
                    <h3 className="UpdateButton__SLidingButtons__Text">
                      <FlagRoundedIcon sx={{ fontSize: 40 }} /></h3>
                  </div>
                  <div
                    className={"UpdateButton__SLidingButtons"}
                    style={{ marginTop: 40 }}
                  >
                    <h3 className="UpdateButton__SLidingButtons__Text"><LinkRoundedIcon sx={{ fontSize: 40 }} /></h3>
                  </div>
                  <div
                    className={"UpdateButton__SLidingButtons"}
                    style={{ marginTop: 40 }}
                  >
                    <h3 className="UpdateButton__SLidingButtons__Text"><BookmarkAddRoundedIcon sx={{ fontSize: 40 }} /></h3>
                  </div>
                  <div
                    className={"UpdateButton__SLidingButtons"}
                    style={{ marginTop: 40 }}
                  >
                    <h3 className="UpdateButton__SLidingButtons__Text"><QuestionMarkRoundedIcon sx={{ fontSize: 40 }} /></h3>
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Updates;
