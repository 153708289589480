import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Sidenav from "../../../components/navigation/sidenav/Sidenav";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Summary from "../components/Summary/Summary";
import { Col, Container, Row } from "react-bootstrap";
import dashboardIllustration from "../../../assets/images/undraw_job_offers_re_634p.svg";
import MarkChatUnreadRoundedIcon from '@mui/icons-material/MarkChatUnreadRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Dashboard() {
  const { projectType, projectId } = useParams();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `https://saniba-61fedb763126.herokuapp.com/briefs/${projectId}`
        );
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  return (
    <div className="projectPage">
      <div className="projectPage__navWraper">
        <Sidenav projectId={projectId} projectType={projectType} />
      </div>
      <div className="projectPage__Content">
        <DashboardDetails />
      </div>
    </div>
  );
}

function DashboardDetails() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const [hoveredDiv, setHoveredDiv] = useState(null);
  const [clickedDiv, setClickedDiv] = useState(null);
  const [briefData, setBriefData] = useState(null);
  const currentUrl = useSelector((state) => window.location.pathname);
  const [API_URL] = useState("https://saniba-61fedb763126.herokuapp.com/");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let briefId = currentUrl.split('/').pop();
        if (briefId === "offers" || briefId === "updates" || briefId === "documents") {
          briefId = currentUrl.split('/');
          briefId = briefId[briefId.length - 2]
        }
        const response = await fetch(
          `${API_URL}briefs/${briefId}`
        );
        const data = await response.json();
        setBriefData(data.brief);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleMouseEnter = (id) => {
    setHoveredDiv(id);
  };

  const handleMouseLeave = () => {
    setHoveredDiv(null);
  };

  const handleClick = (id) => {
    setClickedDiv(id);
  };


  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} sm={12}>
            <Summary />
          </Col>
          {briefData && (
            <>
              <Col lg={10} sm={12}>
                <div className="Dashboard__PrimaryCard"
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(1)}
                >
                  <div className="Dashboard__Card__Content">
                    <p className="Dashboard__Card__Title">Stage: {briefData.currentStage}</p>
                    <div className="Dashboard__Card__Content__Inner">
                      <div className="Dashboard__Card__Content__Inner__DisplayFlex">
                        <div className="Dashboard__Card__Content__Title">START WITH BRIEF 1</div>
                        <Carousel
                          className="Stages__Items"
                          responsive={responsive}
                          >
                          <div className="Stages__Item">
                            <div className="Stages__Icon">
                              <DoneRoundedIcon sx={{ fontSize: 30, strokeWidth: 1 }} />
                            </div>
                            <h4 className="Stages__Title">Found a Dealer</h4>
                          </div>

                          <div className="Stages__Item">
                            <div className="Stages__Icon">
                              <DoneRoundedIcon sx={{ fontSize: 30, strokeWidth: 1 }} />
                            </div>
                            <h4 className="Stages__Title">Project Report</h4>
                          </div>
                          <div className="Stages__Item">
                            <div className="Stages__Icon">
                              <DoneRoundedIcon sx={{ fontSize: 30, strokeWidth: 1 }} />
                            </div>
                            <h4 className="Stages__Title">Project Report</h4>
                          </div>
                          <div className="Stages__Item">
                            <div className="Stages__Icon">
                              <DoneRoundedIcon sx={{ fontSize: 30, strokeWidth: 1 }} />
                            </div>
                            <h4 className="Stages__Title">Project Report</h4>
                          </div>
                          <div className="Stages__Item">
                            <div className="Stages__Icon">
                              <DoneRoundedIcon sx={{ fontSize: 30, strokeWidth: 1 }} />
                            </div>
                            <h4 className="Stages__Title">Project Report</h4>
                          </div>
                          <div className="Stages__Item">
                            <div className="Stages__Icon">
                              <DoneRoundedIcon sx={{ fontSize: 30, strokeWidth: 1 }} />
                            </div>
                            <h4 className="Stages__Title">Project Report</h4>
                          </div>

                        </Carousel>
                        <div className="Dashboard__Card__Content__Paragraph">Dive in to specialized brief for best experience.</div>
                      </div>
                      <img className="Dashboard__Card__Content__Illustration" src={dashboardIllustration} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(1)}
                  className={hoveredDiv === 1 ? "Dashboard__PrimaryCard__ButtonHover" : "Dashboard__PrimaryCard__Button"}>
                  <div
                    className={"Dashboard__PrimaryCard__SlidingButtons"}
                  >
                    <h3 className="Dashboard__PrimaryCard__SlidingButtons__Text">CLIENT</h3>
                  </div>
                  <div
                    className={"Dashboard__PrimaryCard__SlidingButtons"}
                    style={{ marginTop: 32 }}
                  >
                    <h3 className="Dashboard__PrimaryCard__SlidingButtons__Text">DEALER</h3>
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="Dashboard__Card">
                  <div className="Dashboard__Card__Content">
                    <p className="Dashboard__Card__Title">Stages{briefData.currentStage}</p>
                    <div className="Dashboard__Card__Content__Title">START WITH BRIEF</div>
                    <div className="Dashboard__Card__Content__Paragraph" style={{ width: '80%', height: 180, color: '#171717', fontSize: 30 }}>Dive in to specialized brief for best experince.</div>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div >
  );
}

export default Dashboard;
