import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, ListGroup, Image } from 'react-bootstrap';
import "./Profile.css";
import SidenavProfile from '../../components/navigation/sidenavProfile/SidenavProfile2'

function Profile() {
  const handleCopyID = () => {
    // Logic to copy user ID
    const userID = "123456"; // Example user ID
    navigator.clipboard.writeText(userID);
    alert("User ID copied to clipboard");
  };
  return (

    <div className="projectPage">
      <div className="projectPage__navWraper">
        <SidenavProfile />
      </div>
      <div className="projectPage__Content">

        <Container>
          <h1>Profile Page</h1>

          <ListGroup className="mb-4 border">
            <ListGroup.Item>
              <h2>Account Information</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={3}>
                  <Image src="profile-image-url" roundedCircle />
                </Col>
                <Col>
                  <h3>Name</h3>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p>User ID: 123456</p>
                </Col>
                <Col xs="auto">
                  <Button onClick={handleCopyID}>Copy ID</Button>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>Email: ab******ez@outlook.com</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <Button variant="danger">Close my account</Button>
            </ListGroup.Item>
          </ListGroup>

          <ListGroup className="mb-4 border">
            <ListGroup.Item>
              <h2>Additional Accounts</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>You can create new a Dealer or Client accounts, while still having just one login.</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3>Client Account</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>lorem ipsum.</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <Button>New Client Account</Button>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3>Agency Account</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>Lorem ipsum</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <Button>New Dealer Account</Button>
            </ListGroup.Item>
          </ListGroup>
        </Container>

      </div>
    </div>
  );
}


export default Profile;
