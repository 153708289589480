import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleBrief, toggleSubjectSelected } from '../../../authenticate/features/singleBriefSlice';

import '../../../styles/BriefCards.css'

function FirstSelection() {
    const [API_URL] = useState("https://saniba-61fedb763126.herokuapp.com/");
    const userData = useSelector((state) => state.data.user.user);
    const dispatch = useDispatch();

    const addClick = async (choice) => {
        const data = {
            "MainChoose": choice
        };

        try {
            const response = await fetch(`${API_URL}singleBriefs/${userData.uid}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            dispatch(updateSingleBrief(userData.uid));
            dispatch(toggleSubjectSelected());

        } catch (error) {
            console.error("Error during fetch:", error);
        }
    };

    return (
        <>
            <Container className="MainOptions">
                <Row>
                    <Col sm={12}>
                        <div className="CardDisplayProfiles__Content__Texts">
                            <div className="CardDisplayProfiles__Content__Title">Reach us by brief flow</div>
                            <div className="CardDisplayProfiles__Content__Description" >Proin a sem ornare, tempus felis ac, ornare neque. Proin sollicitudin vel sem ut congue. Curabitur vitae elit a sem varius dignissim vel ac diam. </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="OptionCardMain1">
                            <div style={{ alignSelf: 'stretch', height: 360, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                                <div style={{ width: 644, justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                        <div style={{ color: 'white', fontSize: 33.67, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>SOCIAL MEDIA</div>
                                        <div style={{ width: 361, color: 'white', fontSize: 25.90, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>Looking to boost your online presence and engage effectively on social media? Our Social Media Manager can make your brand shine with tailored strategies and compelling content!</div>
                                    </div>
                                    <img style={{ width: 267, height: 226.30, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/267x226" />
                                </div>
                                <div onClick={() => { addClick("Social Media") }} className="CardButton">
                                    <div className="CardButton__Text">CHOOSE</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="OptionCardMain2" >
                            <div style={{ alignSelf: 'stretch', height: 360, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                                <div style={{ width: 644, justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                        <div style={{ color: 'white', fontSize: 33.67, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>GRAPHIC DESIGN</div>
                                        <div style={{ width: 361, color: 'white', fontSize: 25.90, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>Elevate your brand's look with our tailored graphic design services. We specialize in logos, branding, marketing materials, and digital graphics to bring your vision to life.</div>
                                    </div>
                                    <img style={{ width: 267, height: 226.30, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/267x226" />
                                </div>
                                <div onClick={() => addClick("Graphic Design")} className="CardButton">
                                    <div className="CardButton__Text">CHOOSE</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="OptionCardAlignment">
                    <Col sm={4}>
                        <div className="OptionCardMain3" >
                            <div style={{ alignSelf: 'stretch', height: 360, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                                <div style={{ width: 644, justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                        <div style={{ color: 'white', fontSize: 33.67, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>UI/UX</div>
                                        <div style={{ width: 361, color: 'white', fontSize: 25.90, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>Enhance your digital products with our UX/UI design expertise. We create user-friendly interfaces to boost engagement.</div>
                                    </div>
                                    <img style={{ width: 267, height: 226.30, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/267x226" />
                                </div>
                                <div onClick={() => addClick("UX/UI")} className="CardButton">
                                    <div className="CardButton__Text">CHOOSE</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="OptionCardMain4" >
                            <div style={{ alignSelf: 'stretch', height: 360, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                                <div style={{ width: 644, justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                        <div style={{ color: 'white', fontSize: 33.67, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>WEBSITE</div>
                                        <div style={{ width: 361, color: 'white', fontSize: 25.90, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>Unhappy with your website? We'll revamp it with stunning, results-driven design. Bring your vision to the web with us!</div>
                                    </div>
                                    <img style={{ width: 267, height: 226.30, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/267x226" />
                                </div>
                                <div onClick={() => addClick("Web Development")} className="CardButton">
                                    <div className="CardButton__Text">CHOOSE</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="OptionCardMain5" >
                            <div style={{ alignSelf: 'stretch', height: 360, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                                <div style={{ width: 644, justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                        <div style={{ color: 'white', fontSize: 33.67, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', letterSpacing: 0.84, wordWrap: 'break-word' }}>MARKETING/PR</div>
                                        <div style={{ width: 361, color: 'white', fontSize: 25.90, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>Expand your brand's reach with our expert marketing and PR campaigns.Expand your brand's reach with our expert </div>
                                    </div>
                                    <img style={{ width: 267, height: 226.30, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/267x226" />
                                </div>
                                <div onClick={() => addClick("Marketing and Analytics")} className="CardButton">
                                    <div className="CardButton__Text">CHOOSE</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default FirstSelection;