import React from 'react';
import ReleaseItem from './ReleaseItem';

const ReleaseList = ({ releases }) => {
    return (
        <div>
            {releases.map(release => (
                <ReleaseItem key={release.id} release={release} />
            ))}
        </div>
    );
};

export default ReleaseList;
