import React, { useState, useRef, useEffect } from "react";
import "./WelcomeServices.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function WelcomeServices() {

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Feature Comparison</h2>
            <div className="table-responsive">
                <table className="table table-bordered text-center">
                    <thead>
                        <tr>
                            <th className="sticky-column"></th>
                            <th>Feature 1</th>
                            <th>Feature 2</th>
                            <th>Feature 3</th>
                            <th>Feature 4</th>
                            <th>Feature 5</th>
                            <th>Feature 6</th>
                            {/* Add more features as needed */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="sticky-column"><img src="/path-to-logo/upwork-logo-white.png" alt="Upwork" width="100" /></td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            {/* Add more feature values as needed */}
                        </tr>
                        <tr>
                            <td className="sticky-column"><img src="/path-to-logo/fiverr-logo-white.png" alt="Fiverr" width="100" /></td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            {/* Add more feature values as needed */}
                        </tr>
                        <tr>
                            <td className="sticky-column"><img src="/path-to-logo/freelancer-logo-white.png" alt="Freelancer" width="100" /></td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            {/* Add more feature values as needed */}
                        </tr>
                        <tr>
                            <td className="sticky-column"><img src="/path-to-logo/bionluk-logo-white.png" alt="BiOnluk" width="100" /></td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            {/* Add more feature values as needed */}
                        </tr>
                        <tr>
                            <td className="sticky-column"><img src="/path-to-logo/toptal-logo-white.png" alt="Toptal" width="100" /></td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>No</td>
                            {/* Add more feature values as needed */}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default WelcomeServices;
