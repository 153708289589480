import React, { useState, useRef, useEffect } from "react";
import "./WelcomeBanner.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ReactComponent as Logo} from "../../../assets/logo/claylogo.svg"

function WelcomeBanner() {

    return (
        <div class="container banner-section my-4">
            <div class="banner-wrapper position-relative">
                <div class="banner-image"></div>
                <div class="banner-content text-center">
                    <h1 class="banner-title">What’s New in Saniba</h1>
                    <p class="banner-subtitle">Work with Saniba Brief and chat with Semi</p>
                </div>
                <div class="logo-wrapper position-absolute">
                    <Logo
                        height="50"
                        className='logo'
                    />
                </div>
            </div>
        </div>
    );
}

export default WelcomeBanner;
