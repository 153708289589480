import React, { useState, useRef, useEffect } from "react";
import "./WelcomeFeatures.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const cardsData = Array.from({ length: 23 }, (_, index) => ({
    id: index + 1,
    title: `Card Title ${index + 1}`,
    description: `This is the description for card ${index + 1}.`,
    imgSrc: `https://picsum.photos/400/200?random=${index + 1}`, // Random images for demonstration
}));

function WelcomeFeatures() {

    return (
        <div className="container my-5">
            {/* Left and Right Section */}
            <LeftRightSection />

            {/* Another Image and Text Section Below */}
            <ImageTextSection />

            {/* Explore More Features Section */}
            <ExploreMoreFeatures />
        </div>

    );
}

const LeftRightSection = () => {
    return (
        <div className="row mb-4">
            <div className="col-md-6 text-section mb-4 mb-md-0">
                <CategoryText />
            </div>
            <div className="col-md-6 image-section mb-4 mb-md-0">
                <div className="image-wrapper" />
            </div>
        </div>
    );
};

const CategoryText = () => (
    <div>
        <div className="col-md-6 another-text-section">
            <h2 className="big-title">Big Title</h2>
            <p className="description-text">This is the description text explaining the details.</p>
            <ul className="number-list">
                <li>First item on the list</li>
                <li>Second item on the list</li>
                <li>Third item on the list</li>
            </ul>
        </div>
    </div>
);

const ImageTextSection = () => {
    return (
        <div className="row mb-4">
            <div className="col-md-6 another-image-section mb-4 mb-md-0">
                <div className="another-image-wrapper" />
            </div>
            <div className="col-md-6 another-text-section">
                <h2 className="another-title">Another Big Title</h2>
                <p className="another-description-text">Additional text goes here describing another feature or detail.</p>
            </div>
        </div>
    );
};

const ExploreMoreFeatures = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [visibleCards, setVisibleCards] = useState(3);
    const cardsContainerRef = useRef(null); // Ref to scroll to the top of the cards

    const toggleCards = () => {
        if (isExpanded) {
            // Scroll to the top of the cards when collapsing
            cardsContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setIsExpanded(!isExpanded);
        setVisibleCards(isExpanded ? 3 : cardsData.length);
    };

    return (
        <div>
            {/* Your existing code for sections and layout */}

            {/* Feature Cards Section */}
            <div className="explore-title text-center brand-presentation-text-white">Explore more features</div>
            <div ref={cardsContainerRef} className="row card-container">
                {cardsData.slice(0, visibleCards).map((card) => (
                    <div key={card.id} className="col-lg-4 col-md-6 col-sm-12">
                        <FeatureCard card={card} />
                    </div>
                ))}
            </div>

            {/* Toggle Button */}
            <div className="text-center mt-4 featureButton">
                <button
                    id="toggle-cards-button"
                    className="btn btn-rounded"
                    onClick={toggleCards}
                >
                    {isExpanded ? "View less" : "View more"}{" "}
                    {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </button>
            </div>
        </div>
    );
};

const FeatureCard = ({ card }) => (
    <div className="feature-card p-3 mt-4">
        <LazyLoadImage
            src={card.imgSrc}
            alt={card.title}
            effect="blur"
            className="card-image mb-3"
        />
        <div className="card-text-content">
            <h3 className="card-title">{card.title}</h3>
            <p className="card-description">{card.description}</p>
        </div>
        <div className="learn-more-wrapper">
            <span className="learn-more-text">Learn more</span>
            <span className="ArrowOutwardIcon"><ArrowOutwardIcon /></span>
        </div>
    </div>

);



export default WelcomeFeatures;
