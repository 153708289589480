import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UyapBotForm = () => {
    const [formData, setFormData] = useState({
        kimlik_no: '',
        sifre: '',
        ihale_URL: '',
        min_plus: '',
        max_limit: '',
        remain_open: false,
    });

    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [redirecting, setRedirecting] = useState(false); // New state to track redirecting

    const navigate = useNavigate(); // React router's navigation hook

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Validate form data
    const validateForm = () => {
        const { kimlik_no, sifre, ihale_URL, min_plus, max_limit } = formData;
        if (!kimlik_no || !sifre || !ihale_URL || !min_plus || !max_limit) {
            alert('Please fill in all required fields.');
            return false;
        }
        if (isNaN(kimlik_no) || isNaN(min_plus) || isNaN(max_limit)) {
            alert('Kimlik No, Min Plus, and Max Limit must be numbers.');
            return false;
        }
        return true;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);
        setResponseMessage('');
        setRedirecting(false); // Reset redirecting state

        try {
            const apiUrl = 'http://37.140.242.182:5000/run-bot'; // Replace with your actual API endpoint
            //
            const payload = {
                kimlik_no: formData.kimlik_no,
                sifre: formData.sifre,
                ihale_URL: formData.ihale_URL,
                min_plus: Number(formData.min_plus),
                max_limit: Number(formData.max_limit),
                remain_open: formData.remain_open,
            };

            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setResponseMessage(response.data.status || 'Script triggered successfully!');

            // After successful response, start the redirection countdown
            setTimeout(() => {
                setRedirecting(true); // Indicate the redirect is happening
                setTimeout(() => {
                    navigate('/new-page'); // Replace with the actual route
                }, 1000); // 1 second delay for the redirect after "redirecting..." message
            }, 3000); // 6 seconds delay before starting the redirect process

        } catch (error) {
            console.error('Error triggering script:', error.response ? error.response.data : error.message);

            if (error.response?.data?.error) {
                setResponseMessage(error.response.data.error);
            } else if (error.message) {
                setResponseMessage(error.message);
            } else {
                setResponseMessage('Error triggering script');
            }
        } finally {
            setLoading(false);
        }
    };

    // Loader component
    const Loader = () => (
        <div className="loader" style={styles.loader}></div>
    );

    return (
        <div style={styles.container}>
            <h2>UYAP Bot Configuration</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                {/* Form Fields */}
                <label style={styles.label}>
                    Kimlik No:
                    <input
                        type="number"
                        name="kimlik_no"
                        value={formData.kimlik_no}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                </label>

                <label style={styles.label}>
                    Şifre:
                    <input
                        type="password"
                        name="sifre"
                        value={formData.sifre}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                </label>

                <label style={styles.label}>
                    İhale URL:
                    <input
                        type="url"
                        name="ihale_URL"
                        value={formData.ihale_URL}
                        onChange={handleChange}
                        required
                        style={styles.input}
                        placeholder="https://example.com/ihale"
                    />
                </label>

                <label style={styles.label}>
                    Min Plus:
                    <input
                        type="number"
                        name="min_plus"
                        value={formData.min_plus}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                </label>

                <label style={styles.label}>
                    Max Limit:
                    <input
                        type="number"
                        name="max_limit"
                        value={formData.max_limit}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                </label>

                <label style={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        name="remain_open"
                        checked={formData.remain_open}
                        onChange={handleChange}
                        style={styles.checkbox}
                    />
                    Remain Open
                </label>

                {/* Submit Button */}
                <button type="submit" disabled={loading} style={styles.button}>
                    {loading ? 'Submitting...' : 'Run Bot'}
                </button>
            </form>

            {/* Response Message */}
            {responseMessage && (
                <>
                    <div style={styles.response}>
                        {typeof responseMessage === 'string' ? responseMessage : JSON.stringify(responseMessage)}
                    </div>
                    <div style={styles.redirectingMessage}>
                        Redirecting...
                    </div>
                    <Loader />
                </>
            )}

        </div>
    );
};

// Basic inline styles (add loader styles)
const styles = {
    container: {
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    checkboxLabel: {
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        marginTop: '5px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    checkbox: {
        marginRight: '10px',
    },
    button: {
        padding: '10px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    response: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#e9ecef',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
    },
    redirectingMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#ffebcd',
        borderRadius: '4px',
        fontStyle: 'italic',
    },
    loader: {
        marginLeft: '10px',
        border: '4px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '4px solid #3498db',
        width: '16px',
        height: '16px',
        animation: 'spin 2s linear infinite',
    },
};

// Add keyframes for the loader animation in your CSS
const globalStyles = `
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;

export default UyapBotForm;
