import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './rootReducer'; // Import your combined reducers
import singleBriefReducer from '../features/singleBriefSlice';
import activeProjectReducer from '../features/activeProjectSlice';

export const store = configureStore({
  reducer: {
    data: rootReducer,
    singleBrief: singleBriefReducer,
    activeProject: activeProjectReducer,
  }
});
